.table {
  width: fit-content;
  border-collapse: separate;
  table-layout: fixed;

  font-family: "Inter";

  border-spacing: 4px;

  .thead {
    .tr {
      .th {
        height: 54px;
        background-color: #f7f7f7;
        vertical-align: middle;

        border-radius: 4px;

        font-size: 15px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: -0.02em;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

        text-transform: capitalize;

        &.total {
          background-color: #f7f1ea;
          border-color: #f7f1ea;
          text-transform: none;
        }

        &:first-of-type {
          text-align: left;
        }
      }
    }
  }

  .tbody {
    .tr {
      .td {
        cursor: default;

        height: 52px;
        vertical-align: middle;

        background-color: white;

        border-radius: 4px;

        &.total {
          background-color: #f7f1ea;
          border-color: #f7f1ea;
        }

        &:first-of-type {
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: -0.02em;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          padding-right: 8px;

          &.total {
            background-color: #f7f1ea;
            border-color: #f7f1ea;
          }
        }

        &:not(:first-of-type) {
          border: 1px solid #d7d7d7;
          text-align: center;
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
          letter-spacing: -0.02em;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;

          &.total {
            background-color: #f7f1ea !important;
            border-color: #f7f1ea !important;
          }
        }
      }

      &.bold {
        .td {
          font-weight: 800;
        }
      }

      &.purple {
        .td {
          background-color: #f3eeff;
          border-color: #f3eeff;
        }
      }

      &.editable {
        .td {
          &:first-of-type {
            background-color: #caf711;
          }

          &:not(:first-of-type) {
            border-color: #caf711;
            position: relative;
          }

          .input {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            border: none;
            outline: none;

            font-family: inherit;
            text-align: inherit;
            font-weight: inherit;
            font-size: inherit;
            line-height: inherit;
            letter-spacing: inherit;
            text-underline-position: inherit;
            text-decoration-skip-ink: inherit;

            font-style: normal;

            border-radius: inherit;

            &:focus {
              border: 1px solid #caf711;
            }

            &.inputError {
              border-color: red;
            }
          }
        }
      }
    }
  }
}

.expanded-title {
  font-family: "Inter";

  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  & > .expand-icon {
    flex-shrink: 0;
    flex-grow: 0;

    display: grid;
    place-content: center;

    width: 32px;
    height: 32px;
  }

  & > span {
    //Максимум 2 строки
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    font-family: "Inter";
  }
}

.title-percentage-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2px;

  .input {
    &-container {
      @supports not (field-sizing: content) {
        display: inline-grid;
        width: min-content;
      }
    }

    outline: none;

    flex-shrink: 1;
    field-sizing: content;

    @supports not (field-sizing: content) {
      width: 100%;
      min-width: 62px;
      text-align: center;
    }

    padding: 6px 14px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    background-color: white;
    color: #000000;

    font-family: "Inter";
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    &:focus-visible {
      border-color: #b9b9b9;
    }

    &:disabled {
      border-color: #f4f4f4;
      background-color: #f4f4f4;
    }
  }

  .inputError {
    border-color: #eb5757;
  }
}

.sticky {
  position: sticky;

  z-index: 1;

  &.top {
    top: 0;
  }
  &.left {
    left: 0;
  }
}
