@import "../../assets/styles/common.scss";

.selectContainer {
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 8px;

  padding-bottom: 19px;

  .label {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-align: left;

    color: $colorTextPrimary;
  }

  .errorText {
    position: absolute;
    bottom: 0;
    left: 0;

    font-size: 13px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: -0.02em;
    text-align: left;
    color: $colorErrorNew;
  }

  :global {
    .ant-select {
      font-family: "Inter" !important;
      font-style: normal !important;

      &-arrow {
        height: 16px !important;
        width: 16px !important;

        margin-top: -8px !important;
      }

      &-focused {
        border-color: $colorTextSecondary;
      }

      &-single {
        height: max-content !important;
      }

      &-disabled {
        .ant-select-arrow {
          svg path {
            stroke: $colorTextSecondary !important;
          }
        }

        & > .ant-select-selector {
          border: none !important;

          & > .ant-select-selection-item {
            color: $colorTextSecondary !important;
          }
        }
      }

      &-selector {
        border: 1px solid $colorStrokePrimary !important;
        border-radius: 4px !important;
        padding: 12px !important;
        height: 42px !important;
        box-shadow: none !important;

        .ant-select-selection {
          background-color: $colorPrimary !important;

          &-item {
            font-family: "Inter" !important;
            font-style: normal !important;
            font-weight: 500 !important;
            font-size: 14px !important;
            line-height: 16px !important;
            letter-spacing: -0.02em !important;
            color: $colorTextPrimary !important;
          }

          &-placeholder {
            padding-inline-end: 0 !important;
            font-family: "Inter" !important;
            font-style: normal !important;
            font-weight: 500 !important;
            font-size: 14px !important;
            line-height: 16px !important;
            font-family: "Inter";
            letter-spacing: -0.02em !important;
            color: $colorTextSecondary !important;
          }
        }
      }
    }
  }
}

.select-error {
  :global(.ant-select-selector) {
    border-color: $colorErrorNew !important;
  }
}
