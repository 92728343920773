.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 16px 20px;

  font-family: "Inter";
}

.files-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.file-container {
  position: relative;

  width: 88px;
  height: 88px;

  border: 1px solid #d3d6db;
  border-radius: 12px;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .remove-button {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;

    width: 20px;
    height: 20px;

    background-color: #000000;
    border: none;
    border-radius: 100%;

    display: grid;
    place-content: center;

    &:active {
      background-color: #00000063;
    }
  }
}
