.table {
  width: 100%;
  border-collapse: collapse;

  &-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }

  &-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -1%;
  }
}

.th,
.td {
  border: 1px solid #e3e5e8;
  height: 40px;
  vertical-align: middle;
  padding: 8px;
}

.thead {
  .th {
    background-color: #f7f7f7;
    text-align: left;

    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -2%;
    color: #272729;
  }
}

.tbody {
  .tr {
    &.highlight {
      .td {
        font-weight: 700;
      }
    }

    .td {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0px;

      &.positive {
        color: #23c052;
      }
      &.negative {
        color: #ff9696;
      }

      &.placeholder {
        color: #b4b4b4;
      }
    }
  }
}
