@import "../../assets/styles/common.scss";

.page {
  &-wrapper {
    font-family: "Inter";
    padding: 16px 20px;
  }
  &-header {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 32px;
  }
  &-title {
    font-family: "Inter";
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    letter-spacing: 0px;
    span {
      display: inline-block;
      text-transform: capitalize;
    }
  }
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}

.tabs {
  display: flex;
  align-items: center;
  gap: 16px;

  width: 100%;

  padding-bottom: 12px !important;

  border-bottom: 1px solid #dadada;

  .tab {
    cursor: pointer;
    position: relative;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0px;
    text-transform: uppercase;
    color: #272729;

    width: fit-content;

    transition: all 0.25s ease;

    &.active {
      cursor: default;
      color: #6d37f6;

      &::after {
        content: " ";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -13px;
        z-index: 1;

        width: 100%;
        height: 1px;
        background-color: #6d37f6;
      }
    }
  }
}

.tables-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
