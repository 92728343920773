@import "../styles.scss";

.ant-checkbox-wrapper {
  margin-right: 8px;
}

.ant-dropdown-menu {
  overflow: auto;
}

.ant-dropdown-trigger {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;

  .ant-space-item span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
    height: 16px;
    display: block;
    padding-right: 20px;
  }
}

.statements__wrapper {
  padding: 0 20px 40px;
  max-height: calc(100vh - 56px);
  overflow: auto;
  height: 100%;

  .statement-details {
    position: fixed;
    bottom: 0;
    right: 0;
    width: calc(100vw - 280px);
    background-color: $colorNeutral90;
    padding: 16px 24px;
    border-radius: 16px 16px 0 0;
    display: flex;
    flex-flow: row wrap;

    &__item {
      flex: 1;
      gap: 20px;
      .statement-details__title {
        @include body2Medium;
        color: #a4a5b0;
      }
      .statement-details__content {
        @include subtitleSemiBold;
        color: #fff;
      }
    }
  }
  .popupModal__main {
    .ant-upload-drag {
      transition: 200ms ease-in-out;
      background: $colorNeutral0;
      border: 1px solid $colorNeutral20;

      &:hover {
        border-color: $colorNeutral40;
        background: $colorNeutral5;
      }

      &:active {
        border-color: $colorNeutral60;
        background: $colorNeutral10;
      }
    }

    .ant-upload-text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      font-feature-settings: "tnum" on, "lnum" on;
      color: $colorNeutral90;

      span {
        color: $colorPrimaryDefault;
      }
    }

    .ant-upload-wrapper.disabled {
      .ant-upload-drag {
        border-color: $colorNeutral20;
        background: $colorNeutral10;
      }

      .ant-upload-text {
        color: $colorNeutral50;

        span {
          color: $colorNeutral50;
        }
      }
    }

    .attachment {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      background: $colorNeutral5;
      border-radius: 12px;
      padding: 8px 12px;
      margin-top: 8px;

      div {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        gap: 2px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        font-feature-settings: "tnum" on, "lnum" on;
        color: $colorNeutral90;

        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: $colorNeutral60;
        }
      }

      svg {
        cursor: pointer;
        color: $colorNeutral60;
        &:hover {
          color: $colorNeutral80;
        }

        &:active {
          color: $colorNeutral100;
        }
      }
    }
  }
}

.statements__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  gap: 16px;

  &__title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: $colorNeutral90;
  }

  & button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    gap: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    max-width: fit-content !important;

    flex-shrink: 0;
    flex-grow: 0;
  }
}

.statements__underheader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px 0 8px;
  gap: 8px;

  svg {
    transition: 200ms ease-in-out;
  }
  .ant-dropdown-open svg {
    transform: rotate(180deg);
  }
}

.statements-table {
  &__wrapper {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    border: 1px solid $colorNeutral20;
    border-radius: 10px;
    margin-bottom: 35px;
  }

  &__header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    background: $colorNeutral5;
    * {
      @include captionSemiBold;
    }

    &-element {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px 0px 12px;
      gap: 8px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: $colorNeutral60;
      border-bottom: 2px solid $colorNeutral20;
      white-space: nowrap;
      overflow: hidden;

      &:first-of-type {
        padding-left: 8px;
      }
      &.sorted {
        svg {
          min-width: 16px;
        }
      }
      .divider {
        height: 16px;
        width: 1px;
        min-width: 1px;
        background-color: $colorNeutral30;
      }

      svg {
        color: $colorNeutral40;

        path {
          transition: 100ms linear;
        }

        &.asc {
          path:first-child {
            color: $colorNeutral80;
          }
        }
        &.desc {
          path:last-child {
            color: $colorNeutral80;
          }
        }
      }

      &:first-child {
        border-top-left-radius: 10px;
      }

      &.sorted {
        user-select: none;
        &:hover {
          color: $colorNeutral40;
          background: $colorNeutral20;
          border-bottom: 2px solid $colorNeutral30;
        }

        &:active {
          color: $colorNeutral50;
          background: $colorNeutral30;
          border-bottom: 2px solid $colorNeutral40;
        }
      }
    }
  }

  &__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    color: $colorNeutral90;

    &-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0;
      background: $colorNeutral0;
      &:hover {
        background-color: $colorNeutral5;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $colorNeutral20;
      }
    }

    &-element {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px 8px;
      gap: 4px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      @include body2Regular;

      .statements-table__date {
        background-color: $colorNeutral10;
        padding: 4px 12px;
        border-radius: 10px;
        cursor: pointer;
        &:hover {
          background-color: $colorNeutral20;
        }
        &--active,
        &--active:hover {
          background-color: $colorNeutral90;
          color: white;
        }
      }
    }
  }
}

.f120 {
  flex: 1 1;
  max-width: 120px;
}

.f144 {
  flex: 1 1;
  max-width: 144px;
}

.f260 {
  flex: 1 1;
}

.f284 {
  flex: 1 1;
  width: 284px;
}

.f290 {
  flex: 1 1;
  width: 290px;
  max-width: 290px;
}

.f150 {
  width: 150px;
}

.f154 {
  flex: 1 1;
  width: 154px;
  max-width: 280px;
}

.f156 {
  width: 156px;
}

.cursor-pointer {
  cursor: pointer;
}

.ant-pagination-item {
  @include body1Regular;
}

.ant-pagination-item-active {
  border: none !important;
  background-color: $colorNeutral20 !important;
  a {
    color: $colorPrimaryDark !important;
  }
}
.statements-table__date {
  @include body1Medium;
  font-size: 16px !important;
}

.clear-single-filter {
  position: absolute;
  right: 0;
  padding: 5px;
  top: 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 0 5px 5px #ffffff;
  border-radius: 5px;
}
.ant-space {
  position: relative;
}
