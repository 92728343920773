$grayColor: #d9d9d9;

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;

  font-family: "Inter";
  font-weight: 500;
  font-style: normal;
  letter-spacing: -2%;
  color: #000;

  transition: all 0.1s ease-in;

  &.primary {
    background-color: #caf711;

    &:not(:disabled) {
      &:hover {
        background-color: #cdff00;
      }

      &:active {
        background-color: #bde41c;
      }
    }

    &:disabled {
      background-color: #f2f2f2;
    }
  }

  &.outlined {
    background-color: transparent;
    border: 1px solid $grayColor;

    &:not(:disabled) {
      &:hover {
        border-color: #999999;
      }

      &:active {
        border-color: $grayColor;
        color: $grayColor;
      }
    }
  }

  &.empty {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
  }

  &:disabled {
    cursor: not-allowed;
    color: $grayColor;
  }
}
