.wrapper {
  position: relative;
}

.input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}

.label {
  width: fit-content;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 22px 14px;

  border: 1px solid #d6d6d6;
  border-radius: 8px;

  transition: all 0.15s ease-in;

  .icon {
    transition: all 0.15s ease-in;
    path {
      transition: all 0.15s ease-in;
    }
  }

  span {
    transition: all 0.15s ease-in;

    font-family: "Inter";
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -2%;

    color: #999999;
  }

  &:hover {
    border-color: #999999;

    .icon {
      path {
        fill: #6d37f6;
      }
    }
  }

  &:active {
    transition: all 0.1s;

    border-color: #d9d9d9;
    span {
      color: #d9d9d9;
      transition: all 0.1s;
    }
    .icon {
      path {
        fill: #d9d9d9;
        transition: all 0.1s;
      }
    }
  }
}
