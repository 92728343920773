.tabButtonList {
  display: flex;
  gap: 8px;
}

.tabButton {
  padding: 8px 12px;
  border-radius: 100px;
  outline: none;
  border: 1px solid #d9d9d9;
  color: #000;
  background-color: transparent;

  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -2%;

  transition: all 0.15s ease-in;

  &:hover {
    border-color: #999999;
  }

  &.active {
    cursor: default;

    border: 1px solid #6d37f6;
    color: #6d37f6;
  }
}
