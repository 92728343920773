@import "assets/styles/common.scss";

.table {
  font-family: "Inter";
  width: 100%;

  border-collapse: collapse;

  border: 1px solid #d9d9d9;

  .td,
  .th {
    border: 1px solid #d9d9d9;
  }
}

.thead {
  & > .tr > .th {
    font-size: 15px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-align: left;

    background-color: #f7f7f7;
    color: #272729;

    padding: 19px 8px;
  }
}

.tbody {
  .td {
    vertical-align: middle;

    position: relative;
    padding: 12px 8px;

    &:not(:nth-last-of-type(1)) {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.02em;
      text-align: left;

      color: #272729;
    }
  }
}

.footer {
  .td {
    border: none;
  }

  .sum {
    display: flex;
    align-items: center;
    gap: 25px;

    padding: 12px 8px 12px 48px;

    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #999999;
    }
    span {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.02em;
      text-align: right;
      color: #000000;
    }

    &.disabled {
      background-color: transparent;
      p,
      span {
        color: #999999;
      }
    }
  }
}

.error-message {
  font-size: 13px;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: -0.02em;
  text-align: left;
  color: $colorErrorNew;
}

.bucket-button {
  width: 24px;
  height: 24px;

  border: none;
  background-color: transparent;

  margin: auto;
  display: grid;
  place-content: center;
}

.add-button {
  align-self: flex-start;
  display: flex;
  gap: 8px;
  align-items: center;
  border-color: #d9d9d9;

  span {
    font-family: "Inter";
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -2%;
  }
}

.input-sum {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  border: none;

  padding-left: 8px;

  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  &:focus-visible {
    outline: none;
    border: none;
  }

  &.error {
    outline: 1px solid #eb5757;
  }

  &:disabled {
    color: $colorTextSecondary;
  }
}

.article-selector {
  padding: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;

  gap: 0;

  &.error {
    outline: 1px solid #eb5757;
  }

  :global {
    .ant-select-selector {
      border-radius: 0 !important;
      border: none !important;
      height: 50px !important;

      .ant-select-selection-item {
        line-height: 20px !important;
        margin-top: 4px;
      }
    }
  }
}

.disabled {
  background-color: #f7f7f7;
  .status {
    color: #999999;
  }
}
