@import "../../assets/styles/common.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

.registry-document {
  &__header-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 20px;
    gap: 16px;
  }

  &__breadcrumbs {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    gap: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $colorNeutral50;
    cursor: pointer;
    user-select: none;

    .registry-document__link {
      &:hover {
        color: $colorNeutral70;
      }

      &:active {
        color: $colorNeutral90;
      }

      &.current {
        color: $colorNeutral90;
        cursor: default;
        pointer-events: none;
      }
    }
  }

  &__link-container {
    display: flex;
    gap: 16px;

    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: $colorNeutral90;
      white-space: nowrap;
    }
  }

  &__deleted {
    user-select: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 8px;
    gap: 8px;
    /* Special/Error - bg */
    background: rgba(227, 86, 86, 0.12);

    /* Special/Error - fill */
    border: 1px solid #e35656;
    border-radius: 10px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* Special/Error - fill */
    color: #e35656;
  }

  &__header {
    display: flex;
    width: 100%;
    justify-content: space-between;

    &-link {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 4px;
      gap: 8px;

      width: 32px;
      height: 32px;
      background: $colorNeutral0;
      border: 1px solid $colorNeutral30;
      border-radius: 10px;
      cursor: pointer;

      &:hover {
        background: $colorNeutral10;
        border: 1px solid $colorNeutral40;
      }

      &:active {
        background: $colorNeutral20;
        border: 1px solid $colorNeutral50;
      }
    }

    &-buttons {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-end;
      padding: 0;
      gap: 8px;
      width: 100%;

      .new-inventory {
        display: flex;
        align-items: center;
        @include captionMedium;
        color: $colorInfoFill;
        padding: 8px 12px;
        border-radius: 8px;
        background-color: $colorInfoBg;
      }
    }

    &-button {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 6px 12px;
      gap: 8px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      border-radius: 10px;
      outline: none;
      border: none;

      &.black {
        color: $colorNeutral0;
        background-color: $colorNeutral90;

        &[disabled] {
          background-color: $colorNeutral30;
          color: $colorNeutral0;
        }
      }

      &.grey {
        background-color: $colorNeutral20;
        color: $colorNeutral90;

        &[disabled] {
          background-color: $colorNeutral30;
          color: $colorNeutral0;
        }
      }
    }
  }

  &__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 20px;
    gap: 16px;
  }

  &__info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;
    background: $colorNeutral5;
    border-radius: 20px;
    width: 100%;

    &-column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
      gap: 16px;
      flex: 954 954;
    }

    &-row {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      padding: 0;
      gap: 16px;
      width: 100%;
    }
  }

  &__info-element {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 2px;
    flex: 1 1;
  }

  &__element-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $colorNeutral50;
  }

  &__element-value {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $colorNeutral90;
  }

  &__info-button {
    flex: 158 158;
  }

  &__edit {
    &.hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__order {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;
    background: $colorNeutral5;
    border-radius: 20px;

    .registry-document__info-button {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;
    background: $colorNeutral5;
    border-radius: 20px;

    .registry-document__previews {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0;
      flex-flow: row wrap;
      gap: 8px;

      .registry-document__document {
        width: 88px;
        height: 88px;
        background: $colorNeutral0;
        border: 1px solid $colorNeutral30;
        border-radius: 12px;
        position: relative;

        &:hover {
          .registry-document__view {
            opacity: 1;
            pointer-events: all;
          }
        }

        .registry-document__view {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 8px 4px;
          gap: 16px;
          position: absolute;
          bottom: 0;
          background: rgba(0, 0, 0, 0.4);
          width: 100%;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          opacity: 0;
          pointer-events: none;
          transition: 200ms ease-in-out;
        }

        img {
          border-radius: 12px;
          width: 100%;
          height: 100%;
        }
      }
    }

    .registry-document__download {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0;
      gap: 16px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;

      .registry-document__size {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0;
        gap: 2px;
        color: $colorNeutral60;
      }

      .registry-document__download-button {
        color: $colorNeutral90;
      }
    }
  }

  &__table {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    border: 1px solid $colorNeutral30;
    border-radius: 10px;

    &-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0;
      background: $colorNeutral10;
      border-bottom: 2px solid $colorNeutral30;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: $colorNeutral60;

      &-element {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px 8px 12px 8px;
        gap: 4px;
      }
    }

    &-body {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0;

      &-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0;
        border-bottom: 1px solid $colorNeutral30;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $colorNeutral90;

        .registry-document__table-element {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 12px 8px;
          gap: 4px;
          user-select: none;
          position: relative;
          height: 44px;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;

          &.name,
          &.amount,
          &.sum {
            cursor: pointer;
            border-left: 1px solid $colorNeutral20;
            background: $colorNeutral5;
          }

          &.price.disabled {
            color: $colorNeutral50;
          }

          &.name {
            border-right: 1px solid $colorNeutral20;
          }

          &.non-picked {
            color: $colorInactive;
          }

          &:first-child {
            border-bottom-left-radius: 10px;
          }

          &:last-child {
            border-bottom-right-radius: 10px;
          }

          .search-select {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            color: $colorNeutral90;
            border: none;
            outline: none;
            z-index: -10;

            input {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
            }

            .ant-select-selector {
              width: 100%;
              height: 100%;
              color: $colorNeutral90;
              border: none;
              outline: none;
              align-items: center;
              display: flex;
            }
          }

          input {
            display: none;
          }

          &.selected {
            border: 2px solid $colorNeutral90;
            padding: 10px 6px;

            input {
              padding: 10px 6px;
              background: $colorNeutral5;

              display: initial;
            }

            .search-select {
              display: initial;
              background: $colorNeutral5;
              z-index: 10;
            }
          }

          &.picked {
          }

          &:first-child {
            color: $colorNeutral60;
          }

          .input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            color: $colorNeutral90;
            border: none;
            outline: none;
          }
        }

        &:last-child {
          border: none;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
    }
  }

  &__inv-table {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 10px;
    border: 1px solid $colorNeutral30;

    &__header {
      display: flex;
      align-items: flex-start;
      align-self: stretch;
      border-bottom: 2px solid $colorNeutral20;
      background: $colorNeutral5;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;

      &__element {
        display: flex;
        min-width: 112px;
        padding: 12px 8px 12px 8px;
        align-items: center;
        gap: 8px;
        color: $colorSecondary;
        @include captionSemiBold;
        flex: 3 3;
        min-height: 40px;

        &.editable {
          border-left: 1px solid transparent;

          &:not(:nth-child(2)) {
            border-right: 1px solid transparent;
          }
        }

        &:first-child {
          border-top-left-radius: 10px;
          max-width: 52px;
          min-width: 52px;
          flex: 0 0;
        }

        &:nth-child(2) {
          min-width: 180px;
          flex: 9 9;
        }

        &:nth-child(3) {
          min-width: 70px;
        }

        &:last-child {
          border-top-right-radius: 10px;
          min-width: 40px;

          &.delete {
            flex: 0 0;
          }
        }
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;

      &__row {
        display: flex;
        align-items: flex-start;
        align-self: stretch;
        border-bottom: 1px solid $colorNeutral20;
        background: $colorNeutral0;

        &:last-child {
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;
          border-bottom: none;

          &__element {
            &:first-child {
              border-bottom-left-radius: 10px;
            }

            &:last-child {
              border-bottom-right-radius: 10px;
            }
          }
        }

        &__element {
          display: flex;
          min-width: 112px;
          padding: 12px 8px 12px 8px;
          align-items: center;
          gap: 8px;
          flex: 3 3;
          min-height: 44px;
          height: 100%;
          position: relative;

          color: $colorPrimaryDark;
          @include body2Regular;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: fade;

          &:first-child {
            max-width: 52px;
            min-width: 52px;
            flex: 0 0;
          }

          &:nth-child(2) {
            min-width: 180px;
            flex: 9 9;
          }

          &:nth-child(3) {
            min-width: 70px;
          }

          &:nth-child(9) {
            min-width: 60px;
          }

          &:last-child {
            max-width: 40px;
            min-width: 40px;
            flex: 0 0;
          }

          svg {
            cursor: pointer;
          }

          &.editable {
            border-left: 1px solid $colorNeutral20;
            background: $colorNeutral5;

            &:not(:nth-child(2)) {
              border-right: 1px solid $colorNeutral20;
            }

            .search-select {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              color: $colorNeutral90;
              border: none;
              outline: none;
              z-index: -10;

              input {
                min-width: 100%;
                max-width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
              }

              .ant-select-selector {
                width: 100%;
                height: 100%;
                color: $colorNeutral90;
                border: none;
                outline: none;
                align-items: center;
                display: flex;
              }
            }

            input {
              display: none;
            }

            &.selected {
              border: 2px solid $colorNeutral90;
              padding: 10px 6px;

              input {
                padding: 10px 6px;
                background: $colorNeutral5;
                min-width: 100%;

                display: initial;
              }

              .search-select {
                display: initial;
                background: $colorNeutral5;
                z-index: 10;
              }
            }

            &.picked {
            }

            &:first-child {
              color: $colorNeutral60;
            }

            .input {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              color: $colorNeutral90;
              border: none;
              outline: none;
            }
          }
        }
      }
    }
  }
}

.registry-popup {
  .popupModal__main {
    padding: 8px 20px;
  }
}

.f52 {
  flex: 52 52;
}

.f586 {
  flex: 586 586;
}

.f150 {
  flex: 150 150;
}

.f72 {
  flex: 72 72;
}

.delete-modal__title {
  color: $colorInactive;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 4px;
}
