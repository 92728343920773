.category {
  & > td {
    background: #ffffff;
    font-weight: bold;
  }
}

.subcategory {
  background: #f7f1ea;

  & > td {
    background: #f7f1ea;
    border: none;
  }
}

.expandArrow {
  font-size: 16px;
  transition: transform 0.3s ease;
}

.expandArrow.expanded {
  transform: rotate(90deg);
}

.title {
  position: sticky;
  left: -2px;
  z-index: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.titleFirst {
  z-index: 5;
}
